import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import React, { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';
import { megatronApiRef } from '@internal/plugin-megatron';
import {
  Subscription,
  Workspace,
  PapiFeatures,
} from '@internal/megatron-client-common';
import { useTheme } from '@mui/material/styles';

type FeatureData = Subscription | Workspace;

export const OwnedPapiFeaturePicker = ({
  onChange,
  required,
  formData,
  uiSchema,
  readonly,
}: FieldExtensionComponentProps<any>) => {
  // Access the theme object from the Material-UI theme provider
  const theme = useTheme();
  const { mode, action } = theme.palette;

  const megatronApi = useApi(megatronApiRef);
  const papiFeature = uiSchema?.['ui:options']?.feature as
    | PapiFeatures
    | undefined;

  const [options, setOptions] = useState<FeatureData[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | undefined>('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFeatureData = async () => {
      if (!papiFeature) return;

      setLoading(true);
      setError(null);

      try {
        let fetchedData: FeatureData[] = [];
        switch (papiFeature) {
          case PapiFeatures.SUBSCRIPTION:
            fetchedData = await megatronApi.getAllSubscriptions();
            break;
          case PapiFeatures.WORKSPACE:
            fetchedData = await megatronApi.getAllWorkspaces();
            break;
          default:
            throw new Error(`Unsupported PAPI feature: ${papiFeature}`);
        }
        setOptions(fetchedData);

        // Pre-select value if provided in formData
        if (formData) {
          const preselected = fetchedData.find(
            item => `${item.name} (${item.id})` === formData,
          );
          setSelectedValue(
            preselected ? `${preselected.name} (${preselected.id})` : '',
          );
        }
      } catch (err) {
        setError(
          `Failed to fetch data for ${papiFeature}: ${
            err instanceof Error ? err.message : String(err)
          }`,
        );
      } finally {
        setLoading(false);
      }
    };

    fetchFeatureData();
  }, [megatronApi, formData, papiFeature]);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: FeatureData | null,
  ) => {
    const value = newValue ? `${newValue.name} (${newValue.id})` : '';
    onChange(value);
    setSelectedValue(value);
  };

  if (error || !papiFeature) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 1.5,
          padding: 1.5,
        }}
      >
        <ErrorIcon color="error" />
        <Typography variant="body1" color="error" sx={{ fontWeight: 500 }}>
          {error || 'Missing or invalid PAPI feature configuration.'}
        </Typography>
      </Box>
    );
  }

  return (
    <form>
      <FormControl
        data-testid={`${papiFeature}-id-form`}
        margin="normal"
        fullWidth
        required={required}
      >
        <Autocomplete
          options={options}
          ListboxProps={{
            sx: {
              cursor: 'pointer', // Change the cursor to a pointer hand icon
            },
          }}
          getOptionLabel={option => option.name}
          value={
            options.find(opt => `${opt.name} (${opt.id})` === selectedValue) ||
            null
          }
          onChange={handleChange}
          openOnFocus // Opens dropdown on click
          disabled={readonly}
          loading={loading}
          renderInput={params => (
            <TextField
              {...params}
              label={papiFeature}
              variant="outlined"
              required={required}
            />
          )}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px 10px',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: mode === 'dark' ? action.hover : '#f0f0f0',
                },
              }}
            >
              {option.name}
            </Box>
          )}
        />
      </FormControl>
    </form>
  );
};
