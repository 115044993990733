import {
  configApiRef,
  microsoftAuthApiRef,
  SessionState,
  useApi,
} from '@backstage/core-plugin-api';
import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { microsoftChinaAuthApiRef } from './jumphost-utils';
import { AzureCloudPickerProps } from './schema';
import { useTheme } from '@mui/material/styles';

enum CloudType {
  AzureGlobal = 'AzureGlobal',
  AzureChina = 'AzureChina',
}

interface LoadingState {
  loading: boolean;
  msg: string;
  error?: boolean;
}

const SignedInLabel = ({ isSignedIn }: { isSignedIn: boolean }) => {
  const theme = useTheme();
  const { error, success } = theme.palette;

  return (
    <Typography color={isSignedIn ? success.main : error.main} fontSize={12}>
      {isSignedIn ? (
        <span>
          Signed In{' '}
          <CheckIcon style={{ verticalAlign: 'text-top', height: '14px' }} />
        </span>
      ) : (
        <span>
          Sign in first to select{' '}
          <WarningIcon style={{ verticalAlign: 'text-top', height: '14px' }} />
        </span>
      )}
    </Typography>
  );
};

export const AzureCloudPicker = ({
  onChange,
  formData,
  uiSchema,
}: typeof AzureCloudPickerProps) => {
  const { secrets, setSecrets } = useTemplateSecrets();
  const msAuthApi = useApi(microsoftAuthApiRef);

  const msChinaAuthApi = useApi(microsoftChinaAuthApiRef);
  const configAPI = useApi(configApiRef);
  const apiScopes =
    configAPI.getOptionalStringArray('megatron.mxp.additionalScopes') ?? [];
  const apiScopesChina =
    configAPI.getOptionalStringArray('megatron.mxp.additionalChinaScopes') ??
    [];
  const secretsKey = uiSchema['ui:options']?.secretsKey!;
  const [loadingState, setLoadingState] = useState<LoadingState>({
    loading: false,
    msg: '',
  });

  function handleSignIn(cloud: CloudType) {
    setLoadingState({ loading: true, msg: 'Signing in...' });
    (cloud === CloudType.AzureChina ? msChinaAuthApi : msAuthApi)
      .signIn()
      .finally(() => setLoadingState({ loading: false, msg: '' }));
  }

  function handleGetToken(cloud: CloudType) {
    setLoadingState({ loading: true, msg: 'Getting token...' });
    const [api, scopes] =
      cloud === CloudType.AzureChina
        ? [msChinaAuthApi, apiScopesChina]
        : [msAuthApi, apiScopes];

    api
      .getAccessToken(scopes)
      .then(token => {
        setSecrets({
          ...secrets,
          cloudType: cloud,
          [secretsKey]: token,
        });
        setLoadingState({
          loading: false,
          msg: '',
          error: false,
        });
        onChange(cloud);
      })
      .catch(e => {
        setLoadingState({
          loading: false,
          msg: `Failed to get ${cloud} accesstoken. ${e.message}`,
          error: true,
        });
      });
  }

  const [msAuthState, setMsAuthState] = useState<boolean>(false);
  const [msChinaAuthState, setMsChinaAuthState] = useState<boolean>(false);

  useEffect(() => {
    msAuthApi
      .sessionState$()
      .subscribe(v => setMsAuthState(v === SessionState.SignedIn));
    msChinaAuthApi
      .sessionState$()
      .subscribe(v => setMsChinaAuthState(v === SessionState.SignedIn));
  }, [msAuthApi, msChinaAuthApi]);

  return !secretsKey ? (
    <Backdrop open style={{ zIndex: 9999 }}>
      <Typography variant="h3" color="warning">
        Missing secretsKey in the uiSchema
      </Typography>
    </Backdrop>
  ) : (
    <section>
      <Stack spacing={2} direction="row" gap={4} justifyContent="center">
        {(
          [
            [msAuthState, CloudType.AzureGlobal],
            [msChinaAuthState, CloudType.AzureChina],
          ] as Array<[boolean, CloudType]>
        ).map(([state, cloud]) => (
          <Card>
            <CardContent>
              <SignedInLabel isSignedIn={state} />
              <Typography variant="h3">{cloud}</Typography>
            </CardContent>
            <CardActions style={{ columnGap: '2rem' }}>
              {!state && (
                <Button onClick={() => handleSignIn(cloud)}>Sign In</Button>
              )}
              <Button
                disabled={!state}
                onClick={() => handleGetToken(cloud)}
                variant={formData === cloud ? 'contained' : 'outlined'}
              >
                Select
              </Button>
            </CardActions>
          </Card>
        ))}
      </Stack>

      {loadingState.loading && (
        <Stack
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ padding: '0.5rem' }}
        >
          <CircularProgress color="primary" />
          <Typography variant="body2" sx={{ fontWeight: 200 }} color="primary">
            {loadingState.msg}
          </Typography>
        </Stack>
      )}

      {loadingState.error && (
        <Typography variant="body2" color="error">
          {loadingState.msg}
        </Typography>
      )}

      <output style={{ textAlign: 'right' }}>
        {formData ? (
          <>
            <Typography variant="h6">Selected Cloud</Typography>
            <Typography variant="body1">{formData}</Typography>
          </>
        ) : (
          <Typography variant="body1">
            Please select a cloud
            <br />
            before you continue
          </Typography>
        )}
      </output>
    </section>
  );
};
