import React, { useState, useEffect } from 'react';
import { SignInPageProps } from '@backstage/core-app-api';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PolicyIcon from '@mui/icons-material/Policy';
import HelpIcon from '@mui/icons-material/Help';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import {
  Progress,
  SignInProviderConfig,
  UserIdentity,
  Link,
} from '@backstage/core-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material/';
import { TransitionProps } from '@mui/material/transitions';
import { Config } from '../../../config';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// * Custom Styles
const useStyles = makeStyles(() => ({
  mainWrapper: {
    width: 'min-content',
    position: 'absolute',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (min-width: 1860px)': {
      right: '50%',
    },
    '@media (max-width: 1860px)': {
      left: 'max(15%, 50px)',
    },
    '@media (max-width: 800px)': {
      left: '50px',
    },
  },
  cariadLogoWrapper: {
    width: '650px',
    position: 'relative',
    bottom: 0,
    '@media (max-width: 800px)': {
      width: '450px',
    },
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    width: 'calc(100% - 85px)',
  },
  subtitle: {
    position: 'relative',
    fontWeight: '800',
    fontSize: '2.5rem',
    color: '#f9f9f9',
    '@media (max-width: 1600px)': {
      fontSize: '2rem',
    },
    '@media (max-width: 800px)': {
      fontSize: '1.5rem',
    },
  },
  base: {
    minWidth: '650px',
    minHeight: '100vh',
    backgroundColor: '#000000',
  },
  legal: {
    position: 'absolute',
    left: '100px',
    bottom: '50px',
    '@media (max-width: 1550px)': {
      left: '50px',
    },
    '@media (max-width: 1200px)': {
      bottom: '25px',
    },
    display: 'flex',
    gap: '1rem',
  },
  button: {
    width: '100%',
    borderRadius: '10px',
    backgroundColor: '#1EEF97',
    textTransform: 'none',
    color: '#373741',
    '&:hover': {
      backgroundColor: '#78F5C1',
    },
    '&:active': {
      backgroundColor: '#0DBC73',
    },
  },
  backgroundVideo: {
    position: 'fixed',
    left: 'max(45%, 500px)',
    top: 'calc(50% - 540px)',
    width: '1080px',
    height: '1080px',
    objectFit: 'cover',
    pointerEvents: 'none',
  },
  infoText: {
    display: 'none',
  },
}));

type SingleSignInPageProps = SignInPageProps & {
  provider: SignInProviderConfig;
  auto?: boolean;
};

type signinOpts = { showPopup: boolean };

// * Custom sign-in page component
const CustomSignInPage: React.FC<SingleSignInPageProps> = ({
  provider,
  auto,
  onSignInSuccess,
}) => {
  const classes = useStyles();
  const authApi = useApi(provider.apiRef);
  const configApi = useApi(configApiRef);
  const [helpOpen, setHelpOpen] = useState(false);
  const [error, setError] = useState<Error>();
  const [showLoginPage, setShowLoginPage] = useState<boolean>(false);

  const { onboardingDocumentationUrl, onboardingProcessUrl } =
    configApi.get<Config['megatron']['mxp']>('megatron.mxp');

  // * Handler for signin process, user initiated or automatic.
  const handleSignin = React.useCallback(
    async ({ showPopup }: signinOpts) => {
      try {
        let identityResponse;
        // * Only if it is user initiated does the popup show
        if (showPopup) {
          identityResponse = await authApi.getBackstageIdentity({
            instantPopup: true,
          });
        } else {
          identityResponse = await authApi.getBackstageIdentity({
            optional: true,
          });
        }
        if (!identityResponse) {
          setShowLoginPage(true);
          return;
        }
        const profile = await authApi.getProfile();
        onSignInSuccess(
          UserIdentity.create({
            identity: identityResponse.identity,
            authApi,
            profile,
          }),
        );
      } catch (err: any) {
        setError(err);
        setShowLoginPage(true);
      }
    },
    [authApi, onSignInSuccess],
  );

  // * Do an initial check if to see if any logged-in session exists.
  // * If it does, SignIn Page isn't rendered, and the user is taken directly to home page.
  useEffect(() => {
    if (auto) {
      handleSignin({ showPopup: false });
    }
  }, [handleSignin, auto]);

  return showLoginPage ? (
    <main className={classes.base}>
      <video
        autoPlay
        muted
        loop
        src="/assets/CARIAD_Cloud.mp4"
        className={classes.backgroundVideo}
      />
      <article className={classes.mainWrapper}>
        <header>
          <img
            src="/assets/CARIAD_WHITE_TRANSPARENT.png"
            alt="Cariad Logo"
            className={classes.cariadLogoWrapper}
          />
        </header>
        <section className={classes.formWrapper}>
          <Typography variant="h2" className={classes.subtitle}>
            Power to the developers, makers, and thinkers
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              handleSignin({ showPopup: true });
            }}
            className={classes.button}
            sx={{
              fontSize: {
                md: '1.25rem',
                lg: '1.5rem',
              },
            }}
          >
            {provider.message}
          </Button>
          {error && error.name !== 'PopupRejectedError' && (
            <Typography variant="body1" color="#EE5340">
              {`Something went wrong during your authentication: ${error.message}`}
            </Typography>
          )}
        </section>
      </article>
      <footer className={classes.legal}>
        <Link
          to="https://cariad.technology/de/en/legal-notice.html"
          variant="button"
          target="_blank"
          aria-label="Developer Experience Portal"
        >
          <Button
            variant="text"
            startIcon={<PolicyIcon style={{ fontSize: 30 }} />}
            sx={{
              color: '#f9f9f9',
              fontSize: '1rem',
              textTransform: 'none',
            }}
          >
            Legal Notice
          </Button>
        </Link>
        <Button
          variant="text"
          onClick={() => setHelpOpen(true)}
          startIcon={<HelpIcon style={{ fontSize: 30 }} />}
          sx={{
            color: '#f9f9f9',
            fontSize: '1rem',
            textTransform: 'none',
          }}
        >
          Help
        </Button>
      </footer>

      <Dialog
        open={helpOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setHelpOpen(false)}
      >
        <DialogTitle>Register</DialogTitle>
        <DialogContent>
          In case you haven't been onboarded to MXP, you can do so by reading
          the documentation and registering for the CARIAD AAD Tenant
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            target="_blank"
            href={onboardingDocumentationUrl}
          >
            Onboarding Documentation
          </Button>
          <Button
            variant="contained"
            target="_blank"
            href={onboardingProcessUrl}
          >
            CARIAD AAD Registration
          </Button>
          <Button variant="contained" onClick={() => setHelpOpen(false)}>
            Close Dialog
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  ) : (
    <Progress />
  );
};

export default CustomSignInPage;
