import React, { createContext, useContext, ReactNode } from 'react';

// Context with a default value of `undefined`
const AzureGroupContext = createContext<boolean | undefined>(undefined);

type AzureGroupProviderProps = {
  hasGroupMembership: boolean;
  children: ReactNode;
};

// Provider component to wrap children and provide `hasGroupMembership`
export const AzureGroupProvider = ({
  hasGroupMembership,
  children,
}: AzureGroupProviderProps) => {
  return (
    <AzureGroupContext.Provider value={hasGroupMembership}>
      {children}
    </AzureGroupContext.Provider>
  );
};

// Custom hook to consume `hasGroupMembership` in child components
export const useAzureGroup = () => {
  const context = useContext(AzureGroupContext);
  if (context === undefined) {
    throw new Error('useAzureGroup must be used within an RequireAzureGroup');
  }
  return context;
};
