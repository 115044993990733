/**
 * Enum representing different HTTP methods.
 * These methods define the type of action to be performed in an HTTP request.
 */
export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

/**
 * Enum representing standard HTTP status codes.
 * These codes indicate the result of an HTTP request.
 */
export enum HttpStatusCode {
  NO_CONTENT = 204,
}

/**
 * Enum representing papi endpoints.
 * These codes indicate the endpoints path.
 */
export enum PapiEndpoints {
  WORKSPACE = '/workspace/v1',
  SUBSCRIPTION = '/subscription/v1',
  SPOKE = '/spoke/v1',
  NAMESPACE = '/namespace/v1',
  DOMAIN = '/domain/v1',
  SERVICE_PRINCIPAL = '/servicePrincipal/v1',
  CERTIFICATE = '/certificate/v1',
  ACR_REPO = '/acrRepo/v1',
}

/**
 * Enum representing papi features.
 * These codes indicate the papi features.
 */
export enum PapiFeatures {
  WORKSPACE = 'workspace',
  SUBSCRIPTION = 'subscription',
  SPOKE = 'spoke',
  NAMESPACE = 'namespace',
  DOMAIN = 'domain',
  SERVICE_PRINCIPAL = 'servicePrincipal',
  CERTIFICATE = 'certificate',
  ACR_REPO = 'acrRepo',
}

/**
 * Enum representing different countries.
 * These codes correspond to country names.
 * Add more mappings as needed.
 * The values are sourced from the following reference:- https://sysdocs.mega.cariad.cloud/megatron2/architecture/naming-schema/#geographies
 */
export const countryLookup: Readonly<Record<string, string>> = {
  us: 'United States',
  eu: 'Europe',
  cn: 'China',
  // Add more mappings as needed
};

/**
 * Enum representing different regions.
 * These codes correspond to region names.
 * Add more mappings as needed.
 * The values are sourced from the following reference:- https://sysdocs.mega.cariad.cloud/megatron2/architecture/geographies/
 */
export const regionLookup: Readonly<Record<string, string>> = {
  gwc: 'Germany West Central',
  sdc: 'Sweden Central',
  // Add more mappings as needed
};

/**
 * Enum representing different spoke sizes.
 * These codes correspond to spoke size names & specification.
 * Add more mappings as needed.
 * The values are sourced from the following reference:- https://sysdocs.mega.cariad.cloud/megatron2/architecture/network/#customer-spokes_1
 */
export const spokeSizeLookup: Readonly<Record<string, string>> = {
  xs: 'Xtra Small (Mask - /26, Available IPs - 59)',
  s: 'Small (Mask - /25, Available IPs - 123)',
  m: 'Medium (Mask - /24, Available IPs - 251)',
  l: 'Large (Mask - /23, Available IPs - 507)',
  xl: 'Xtra Large (Mask - /22, Available IPs - 1019)',
  // Add more mappings as needed
};
