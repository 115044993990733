import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@mui/styles';
// import HomeIcon from '@mui/icons-material/Home';
import PolicyIcon from '@mui/icons-material/Policy';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooksOutlined';
import MXPSidebarLogoIcon from './MXPSidebarLogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import BuildIcon from '@mui/icons-material/Build';
import {
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarSubmenuItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  DashboardIcon,
  DocsIcon,
} from '@backstage/core-components';
import MenuIcon from '@mui/icons-material/Menu';
import CategoryIcon from '@mui/icons-material/Category';
import { Config } from '../../../config';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { RequireAzureGroup, megatron2DeveloperGroup } from 'mega-components';

const useSidebarLogoStyles = makeStyles({
  appTitle: {
    lineHeight: '52px',
    fontFamily: 'FKCARIAD-Light',
    fontSize: '32px',
    color: 'white',
  },
  link: {
    margin: '25px auto 0 !important',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    gap: '10px',
  },
  inlineSubmenu: {
    width: '100%',
    backgroundColor: 'rgb(0 0 0 / 10%)',
    '&  > div > a': {
      paddingLeft: '25px',
    },
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <Link to="/" underline="none" className={classes.link} aria-label="Home">
      <MXPSidebarLogoIcon ascended={isOpen} />
      {isOpen && <span className={classes.appTitle}>MEGA</span>}
    </Link>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const configApi = useApi(configApiRef);
  const classes = useSidebarLogoStyles();

  const { documentationUrl, costDashboardUrl } =
    configApi.get<Config['megatron']['mxp']>('megatron.mxp');

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          <SidebarDivider style={{ marginTop: '20px' }} />
          <SidebarItem
            icon={() => <CategoryIcon />}
            to="product-meta"
            text="Product Meta"
          />
          {/* <SidebarItem icon={() => <HomeIcon />} to="catalog" text="Catalog" /> */}
          <SidebarItem
            icon={() => <LibraryBooksIcon />}
            to="create"
            text="Templates"
          />
          <RequireAzureGroup
            azureGroups={megatron2DeveloperGroup}
            errorPage={<></>}
          >
            <SidebarItem
              icon={() => <CategoryIcon />}
              to="/megatron"
              text="Megatron"
            />
          </RequireAzureGroup>
          <RequirePermission
            permission={devToolsAdministerPermission}
            errorPage={<></>}
          >
            <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
          </RequirePermission>
          <SidebarDivider />
          <div className={classes.inlineSubmenu}>
            <SidebarSubmenuItem
              title="Documentation"
              to={documentationUrl}
              icon={DocsIcon}
            />
            <SidebarSubmenuItem
              title="Cost Dashboard"
              to={costDashboardUrl}
              icon={DashboardIcon}
            />
          </div>
          {/* End global nav */}
          <SidebarDivider />
          <SidebarScrollWrapper>
            {/* Items in this group will be scrollable if they run out of space */}
          </SidebarScrollWrapper>
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
        <SidebarItem
          to="https://cariad.technology/de/en/legal-notice.html"
          target="_blank"
          aria-label="Developer Experience Portal"
          style={{ textDecoration: 'none' }}
          icon={() => <PolicyIcon />}
          text="Legal Notice"
        />
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
