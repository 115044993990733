import {
  ApiRef,
  BackstageIdentityApi,
  createApiRef,
  OAuthApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
} from '@backstage/core-plugin-api';
import * as forge from 'node-forge';

export interface SSHKeyPair {
  privateKey: string;
  publicKey: string;
}

export const generateSSHKey = async (): Promise<SSHKeyPair> => {
  return new Promise(resolve => {
    forge?.pki?.rsa?.generateKeyPair(
      { bits: 2048, workers: -1 },
      (err: any, keypair: any) => {
        if (err) throw err;

        resolve({
          privateKey: forge.ssh.privateKeyToOpenSSH(keypair.privateKey),
          publicKey: forge.ssh.publicKeyToOpenSSH(keypair.publicKey),
        });
      },
    );
  });
};

export const downloadString = (s: string, filename: string, type: string) => {
  // create a pseudo-element with the string as a blob
  const element = document.createElement('a');
  const file = new Blob([s], { type });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
};

export const microsoftChinaAuthApiRef: ApiRef<
  OAuthApi &
    OpenIdConnectApi &
    ProfileInfoApi &
    BackstageIdentityApi &
    SessionApi
> = createApiRef({
  id: 'auth.microsoft-china-provider',
});
