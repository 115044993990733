import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { OwnedPapiFeaturePicker } from './OwnedPapiFeaturePickerExtension';

export const OwnedPapiFeaturePickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'OwnedPapiFeaturePicker',
    component: OwnedPapiFeaturePicker,
  }),
);
