import {
  ConfigContent,
  ExternalDependenciesContent,
  InfoContent,
} from '@backstage/plugin-devtools';
import { DevToolsLayout } from '@backstage/plugin-devtools';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { RequirePermission } from '@backstage/plugin-permission-react';
import React from 'react';

const DevToolsPage = () => {
  return (
    <DevToolsLayout>
      <DevToolsLayout.Route path="info" title="Info">
        <RequirePermission permission={devToolsAdministerPermission}>
          <InfoContent />
        </RequirePermission>
      </DevToolsLayout.Route>
      <DevToolsLayout.Route path="config" title="Config">
        <RequirePermission permission={devToolsAdministerPermission}>
          <ConfigContent />
        </RequirePermission>
      </DevToolsLayout.Route>
      <DevToolsLayout.Route
        path="external-dependencies"
        title="External Dependencies"
      >
        <RequirePermission permission={devToolsAdministerPermission}>
          <ExternalDependenciesContent />
        </RequirePermission>
      </DevToolsLayout.Route>
    </DevToolsLayout>
  );
};

export const customDevToolsPage = <DevToolsPage />;
