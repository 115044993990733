import React, { useState, useEffect, useCallback } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import CodeMirror from '@uiw/react-codemirror';
import { yaml } from '@codemirror/lang-yaml';

import { downloadString, generateSSHKey } from './jumphost-utils';
import { JumphostDetailsProps } from './schema';
import { useTheme } from '@mui/material/styles';

export interface VmSizeOption {
  name: string;
  spec: string;
}

enum CloudType {
  AzureGlobal = 'AzureGlobal',
  AzureChina = 'AzureChina',
}

export type FormData = {
  productStage: string;
  hub: string;
  cluster: string;
  vmSize: string;
  sshKey: string;
  cloudInit: string;
};

export interface JumphostProductStage {
  name: string;
  hub: string;
  cluster: string;
}

export interface Product {
  identifier: string;
  brand: any;
  longName: string;
  domain?: any;
  leanix?: string;
  owners: Array<string>;
  developers: Array<string>;
  users: Array<string>;
  payments: Array<any>;
  stages: Array<any>;
}

export default interface ProductStage {
  name: string;
  hub: string;
  cluster: string;
}

const vmSizes: Record<CloudType, Array<VmSizeOption>> = {
  AzureGlobal: [
    { name: 'Standard_D2lds_v5', spec: '2 vCPus, 4GB' },
    { name: 'Standard_D2ds_v5', spec: '2 vCPus, 8GB' },
    { name: 'Standard_D4lds_v5', spec: '4 vCPus, 8GB' },
    { name: 'Standard_D4ds_v5', spec: '4 vCPus, 16GB' },
  ],
  AzureChina: [
    { name: 'Standard_D2ds_v5', spec: '2 vCPus, 8GB' },
    { name: 'Standard_D4ds_v5', spec: '4 vCPus, 16GB' },
  ],
};

const INITIAL_FORM_DATA = {
  productStage: '',
  hub: '',
  cluster: '',
  vmSize: '',
  sshKey: '',
  cloudInit: `#cloud-config
apt:
  sources:
    azurecli.list:
      source: deb [arch=amd64] https://packages.microsoft.com/repos/azure-cli/ $RELEASE main
      keyid: BC528686B50D79E339D3721CEB3E94ADBE1229CF
    helm.list:
      source: deb [arch=amd64] https://baltocdn.com/helm/stable/debian/ all main
      keyid: 81BF832E2F19CD2AA0471959294AC4827C1A168A

packages:
  - apt-transport-https
  - ca-certificates
  - gnupg-agent
  - software-properties-common
  - azure-cli
  - helm

runcmd:
  - az aks install-cli`,
};

/*
 This is the actual component that will get rendered in the form
*/
export const JumphostDetails = ({
  rawErrors,
  required,
  formData,
  onChange,
  uiSchema,
}: typeof JumphostDetailsProps) => {
  const [productStages, setProductStages] = React.useState<
    JumphostProductStage[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const { secrets } = useTemplateSecrets();
  const accessToken = secrets[uiSchema['ui:options']?.secretsKey!];
  // TODO: After splitting this into a separate component, we should pass the cloudType as a prop
  const cloudType = secrets.cloudType as CloudType;
  const theme = useTheme();

  const [values, setValues] = useState<FormData>({
    ...INITIAL_FORM_DATA,
    vmSize: vmSizes[cloudType][0].name,
  });
  // State to manage the loading status (initially set to false)
  const [loading, setLoading] = useState<boolean>(false);

  const config = useApi(configApiRef);

  useEffect(() => {
    const newFormData = { ...formData, ...values };
    onChange(newFormData);
  }, [values, formData, onChange]);

  const setFieldValue = (fieldName: keyof FormData, value: any) => {
    setValues(oldValues => ({
      ...oldValues,
      [fieldName]: value,
    }));
  };

  const generateKey = useCallback(
    (editValue: (fieldName: keyof FormData, value: string) => void) => {
      setIsLoading(true);
      generateSSHKey().then(keypair => {
        editValue('sshKey', keypair.publicKey);
        // download magic
        downloadString(
          keypair.privateKey,
          'jumphost-privatekey',
          'application/x-pem-file',
        );
        setIsLoading(false);
      });
    },
    [],
  );

  // load product-stages from graph API
  useEffect(() => {
    const getProductStages = async () => {
      try {
        if (values.productStage === '') {
          const oldValues = { ...values, ...formData };
          setValues(oldValues);
          setProductStages([
            {
              name: oldValues.productStage,
              hub: oldValues.hub,
              cluster: oldValues.cluster,
            },
          ]);
        }
        setLoading(true);
        const backendBaseUrl = config.getString('backend.baseUrl');
        // Fetching users from microsoft graph API
        let stages: JumphostProductStage[] = [];
        stages = await fetch(`${backendBaseUrl}/api/scaffolder/jumpHostInfo`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then(resp => resp.json())
          .then(data => (data ? data : []));

        setProductStages(stages);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setLoading(false); // Stop loader when done
      }
    };
    getProductStages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const handleChange = (event: SelectChangeEvent) => {
    const stage = productStages?.find(
      (pStage: JumphostProductStage) => pStage.name === event.target.value,
    );
    if (stage) {
      setFieldValue('hub', stage.hub);
      setFieldValue('cluster', stage.cluster);
    }
    setFieldValue('productStage', event.target.value);
  };

  return (
    <form>
      <FormControl
        margin="normal"
        fullWidth
        required={required}
        error={rawErrors?.length > 0 && !formData}
      >
        <InputLabel htmlFor="productStage">Product-Stage</InputLabel>
        <Select
          labelId="product-stage-label"
          id="product-stage"
          value={values.productStage}
          onChange={handleChange}
          label="Product-Stage"
        >
          {productStages?.length > 0 ? (
            productStages.map(stage => (
              <MenuItem key={`product-stage-${stage.name}`} value={stage.name}>
                {`${stage.name}`}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
        </Select>
        {loading && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start', // Align content to the left
              gap: 1.5, // Reduce spacing between loader and text
              padding: 0.5,
            }}
            aria-live="polite"
          >
            <CircularProgress
              color="primary"
              aria-label="Fetching access token"
              title="Fetching access token"
              sx={{
                animationDuration: '550ms',
                width: '12px !important',
                height: '12px !important',
              }}
            />
            <Typography variant="body2" color="primary" fontWeight={200}>
              Retrieving Product Stages. Please wait...
            </Typography>
          </Box>
        )}
      </FormControl>
      <Box>
        <TextField
          id="filled-read-only-input"
          label="Hub"
          value={values.hub}
          name="hub"
          disabled
          fullWidth
        />
      </Box>
      <Box style={{ marginTop: 10 }}>
        <TextField
          id="filled-read-only-input"
          label="Cluster"
          value={values.cluster}
          name="cluster"
          disabled
          fullWidth
        />
      </Box>
      <FormControl
        style={{ marginTop: 10 }}
        fullWidth
        required={required}
        error={rawErrors?.length > 0 && !formData}
      >
        <InputLabel htmlFor="vmSize">VM Size</InputLabel>
        <Select
          labelId="vm-size-label"
          id="vm-size-standard"
          placeholder="VM Size"
          label="VM Size"
          value={values.vmSize}
          onChange={event => setFieldValue('vmSize', event.target.value)}
        >
          {vmSizes[cloudType].map(item => (
            <MenuItem
              value={item.name}
            >{`${item.name} (${item.spec})`}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 20,
          marginTop: 10,
        }}
      >
        <TextField
          value={values.sshKey}
          name="sshKey"
          onChange={event => setFieldValue('sshKey', event.target.value)}
          label="Public SSH-Key"
          InputLabelProps={{ shrink: true }}
          helperText={`Create an ssh key with ssh-keygen PEN -t rsa -b 2048 and paste the content of your.pub file or use the "Generate key.. button. (The generation will be done inside the browser and we won't store any private keys)`}
          minRows={6}
          placeholder="ssh-rsa ..."
          fullWidth
          multiline
        />
        <span>or</span>
        <Button
          variant="contained"
          onClick={() => generateKey(setFieldValue)}
          disabled={isLoading}
          title="Clicking on this you'll generate a new keypair. It'll automatically start to download the private key."
        >
          Generate New
        </Button>
      </Box>
      <Box style={{ marginTop: 10 }}>
        <CodeMirror
          theme={theme.palette.mode}
          value={values.cloudInit}
          height="200px"
          extensions={[yaml()]}
          onChange={(codeVal: string) => setFieldValue('cloudInit', codeVal)}
        />
      </Box>
    </form>
  );
};
