import React, {
  ReactNode,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import {
  useApi,
  microsoftAuthApiRef,
  useApp,
} from '@backstage/core-plugin-api';
import { AzureGroupProvider } from './AzureGroupContext';
import jwt from 'jsonwebtoken';

/**
 * Properties for {@link RequireAzureGroup}
 */
export type AzureGroupPermissionProps = {
  azureGroups: string[];
  forceRenderChildren?: boolean; // If true, children will be rendered regardless of group membership (currently useful for the scaffolder page, where we only hide templates, not the entire scaffolder page).
} & {
  /**
   * The error page to be displayed if the user is not allowed access.
   *
   * Defaults to the `NotFoundErrorPage` app component.
   */
  errorPage?: ReactNode;
  children: ReactNode;
};

/**
 * Checks if the given JWT token includes the Azure groups claim.
 *
 * @param idToken - JWT token string to decode.
 * @param azureGroups - Azure groups to verify against the token groups claims.
 * @returns True if the azure group membership found, otherwise false.
 */
export const tokenHasGroupClaim = (
  idToken: string,
  azureGroups: string[],
): boolean => {
  const decodedToken = jwt.decode(idToken, { json: true });
  const groupsClaim = decodedToken?.groups;
  return azureGroups.some(group => groupsClaim?.includes(group)) ?? false;
};

/**
 * A component that conditionally renders children based on Azure AD group membership.
 *
 * @param props - Component properties containing the Azure groups, optional error page, and children.
 * @returns The children if the user is a member of the group, an error page otherwise.
 */
function AzureGroupPermission(
  props: AzureGroupPermissionProps,
): JSX.Element | null {
  const { azureGroups, forceRenderChildren = false } = props;
  const microsoftAuthApi = useApi(microsoftAuthApiRef);
  const app = useApp();
  const [isMember, setIsMember] = useState<boolean>(false);

  const memoizedAzureGroups = useMemo(() => azureGroups, [azureGroups]);

  const checkMembership = useCallback(async () => {
    try {
      const idToken = await microsoftAuthApi.getIdToken();
      setIsMember(tokenHasGroupClaim(idToken, memoizedAzureGroups));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error fetching ID token:', error);
      setIsMember(false);
    }
  }, [microsoftAuthApi, memoizedAzureGroups]);

  useEffect(() => {
    checkMembership();
  }, [checkMembership]);

  if (isMember || forceRenderChildren) {
    return (
      <AzureGroupProvider hasGroupMembership={isMember}>
        {props.children}
      </AzureGroupProvider>
    );
  }

  if (props.errorPage) {
    return <>{props.errorPage}</>;
  }
  // If no explicit error element is provided, the not found page is used as fallback.
  const { NotFoundErrorPage } = app.getComponents();
  return <NotFoundErrorPage />;
}

export { AzureGroupPermission as RequireAzureGroup };
