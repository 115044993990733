import React from 'react';
import {
  SettingsLayout,
  UserSettingsProfileCard,
  UserSettingsAppearanceCard,
  UserSettingsAuthProviders,
  ProviderSettingsItem,
} from '@backstage/plugin-user-settings';
import Grid from '@mui/material/Grid';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { Star } from '@mui/icons-material';
import { microsoftChinaAuthApiRef } from '../../apis';

const providerSettings = (
  <>
    <ProviderSettingsItem
      title="Microsoft"
      description="Provides authentication towards Microsoft APIs and identities"
      apiRef={microsoftAuthApiRef}
      icon={Star}
    />
    <ProviderSettingsItem
      title="MicrosoftChina"
      description="Provides authentication towards Microsoft China APIs and identities"
      apiRef={microsoftChinaAuthApiRef}
      icon={Star}
    />
  </>
);

export const settingsPage = (
  <SettingsLayout>
    <SettingsLayout.Route path="general" title="General">
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} md={6}>
          <UserSettingsProfileCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <UserSettingsAppearanceCard />
        </Grid>
      </Grid>
    </SettingsLayout.Route>
    <SettingsLayout.Route path="providers" title="Authentication Providers">
      <UserSettingsAuthProviders providerSettings={providerSettings} />
    </SettingsLayout.Route>
    {/* <SettingsLayout.Route path="feature-flags" title="Feature Flags">
      <UserSettingsFeatureFlags />
    </SettingsLayout.Route> */}
  </SettingsLayout>
);
