import { createApiRef } from '@backstage/core-plugin-api';
import { Product } from '../types';
import { BaseProductMetaApiClient } from './baseApi';

export interface ProductMetaApi {
  getAllProducts: () => Promise<Array<Product>>;
  getProduct: (productName: string) => Promise<Product>;
  updateProduct: (product: Product) => Promise<void>;
  getCsvExport: () => Promise<void>;
}

export const productMetaApiRef = createApiRef<ProductMetaApi>({
  id: 'plugin.product-meta.service',
});

export class ProductMetaApiClient
  extends BaseProductMetaApiClient
  implements ProductMetaApi
{
  async getAllProducts(): Promise<Array<Product>> {
    const response = await this.fetchWithAuth('/v1/products');
    return response.json();
  }

  async getProduct(productName: string): Promise<Product> {
    const response = await this.fetchWithAuth(`/v1/products/${productName}`);
    return response.json();
  }

  async updateProduct(product: Product): Promise<void> {
    await this.fetchWithAuth(`/v1/products/${product.identifier}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(product),
    });
  }

  async getCsvExport() {
    const resp = await this.fetchWithAuth('/v1/products/export/csv', {
      method: 'GET',
    });

    const content = await resp.text();

    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([content]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `data.csv`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }
}
