import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { JumphostDetails } from './JumphostDetailsExtension';
import { AzureCloudPicker } from './AzureCloudPicker';

export const JumphostDetailsExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'JumphostDetails',
    component: JumphostDetails,
  }),
);

export const AzureCloudPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureCloudPicker',
    component: AzureCloudPicker,
  }),
);
