import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'megatron',
});

export const workspaceRouteRef = createSubRouteRef({
  id: 'megatron/workspace',
  parent: rootRouteRef,
  path: '/workspace/:id',
});

export const workspaceFeatureRouteRef = createSubRouteRef({
  id: 'megatron/workspace/feature',
  parent: rootRouteRef,
  path: '/workspace/:id/:workspaceFeature',
});
