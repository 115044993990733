import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';

import {
  rootRouteRef,
  workspaceRouteRef,
  workspaceFeatureRouteRef,
} from './routes';
import { MegatronApiClient, megatronApiRef } from './apis/papi.api';

export const megatronPlugin = createPlugin({
  id: 'megatron',
  routes: {
    root: rootRouteRef,
    selectedWorkspace: workspaceRouteRef,
    selectedWorkspaceFeature: workspaceFeatureRouteRef,
  },
  apis: [
    // Add ProductMetaApi
    createApiFactory({
      api: megatronApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        microsoftAuthApi: microsoftAuthApiRef,
        configApi: configApiRef,
      },
      factory: ({ discoveryApi, microsoftAuthApi, configApi }) =>
        new MegatronApiClient(discoveryApi, microsoftAuthApi, configApi),
    }),
  ],
});

export const MegatronPage = megatronPlugin.provide(
  createRoutableExtension({
    name: 'MegatronPage',
    component: () => import('./components/Root').then(m => m.RootComponent),
    mountPoint: rootRouteRef,
  }),
);
