import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin } from '@backstage/plugin-api-docs';
import { CatalogEntityPage, catalogPlugin } from '@backstage/plugin-catalog';
import { catalogImportPlugin } from '@backstage/plugin-catalog-import';
import {
  AuthTokenPickerExtension,
  AzureUserPickerExtension,
  OwnedPapiFeaturePickerExtension,
  createTemplateFilter,
} from '@internal/backstage-plugin-scaffolder-frontend-module-mega-actions';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import DarkIcon from '@mui/icons-material/Brightness2';
import LightIcon from '@mui/icons-material/WbSunny';
import { UnifiedThemeProvider } from '@backstage/theme';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { Root } from './components/Root';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
// import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
// import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  customUnifiedDarkTheme,
  customUnifiedLightTheme,
} from './components/theme/cariad-theme';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import CustomSignInPage from './components/signin/SignInPage';
// import { customActions } from '@internal/backstage-plugin-scaffolder-frontend-module-mega-actions';
import { ProductMetaPage } from '@internal/plugin-product-meta';
import {
  JumphostDetailsExtension,
  AzureCloudPickerExtension,
} from '@internal/backstage-plugin-scaffolder-jumphost-react';
import {
  configApiRef,
  useApi,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';
import { MegatronPage } from '@internal/plugin-megatron';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { settingsPage } from './components/user-settings/CustomUserSettingsPage';
import { CustomTemplateListPage } from './components/scaffolder/CustomTemplateListPage';
import {
  RequireAzureGroup,
  megatron2DeveloperGroup,
  useAzureGroup,
} from 'mega-components';

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: false,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: false,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    // Sign In Provided with Azure Auth.
    SignInPage: props => (
      <CustomSignInPage
        {...props}
        auto
        provider={{
          id: 'microsoft-auth-provider',
          title: 'Azure',
          message: 'Sign in using Azure',
          apiRef: microsoftAuthApiRef,
        }}
      />
    ),
  },
  themes: [
    {
      id: 'custom-light',
      title: 'Light Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider
          theme={customUnifiedLightTheme}
          children={children}
        />
      ),
    },
    {
      id: 'custom-dark',
      title: 'Dark Theme',
      variant: 'dark',
      icon: <DarkIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider
          theme={customUnifiedDarkTheme}
          children={children}
        />
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="product-meta" />} />
    {/* <Route
      path="/catalog"
      element={<CatalogIndexPage actions={customActions} />}
    /> */}
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/create"
      element={
        <RequireAzureGroup
          azureGroups={megatron2DeveloperGroup}
          forceRenderChildren
        >
          <ScaffolderPage
            components={{
              EXPERIMENTAL_TemplateListPageComponent: CustomTemplateListPage,
            }}
            templateFilter={entity => {
              const config = useApi(configApiRef);
              const hasGroupMembership = useAzureGroup();
              const filter = createTemplateFilter(config, hasGroupMembership);
              return filter(entity);
            }}
            contextMenu={{
              editor: false,
            }}
            headerOptions={{
              pageTitleOverride: 'Scaffolder Templates',
              subtitle:
                'Request services using standard templates in your organization',
              title: 'CARIAD Megatron Scaffolder Templates',
            }}
          />
        </RequireAzureGroup>
      }
    >
      <ScaffolderFieldExtensions>
        <AuthTokenPickerExtension />
        <AzureUserPickerExtension />
        <OwnedPapiFeaturePickerExtension />
        <JumphostDetailsExtension />
        <AzureCloudPickerExtension />
      </ScaffolderFieldExtensions>
    </Route>
    {/* <Route path="/api-docs" element={<ApiExplorerPage />} /> */}
    {/* <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    /> */}
    <Route path="/settings" element={<UserSettingsPage />}>
      {settingsPage}
    </Route>
    {/* <Route path="/catalog-graph" element={<CatalogGraphPage />} /> */}
    <Route path="/product-meta" element={<ProductMetaPage />} />
    <Route
      path="/megatron"
      element={
        <RequireAzureGroup azureGroups={megatron2DeveloperGroup}>
          <MegatronPage />
        </RequireAzureGroup>
      }
    />
    <Route
      path="/devtools"
      element={
        <RequirePermission permission={devToolsAdministerPermission}>
          <DevToolsPage />
        </RequirePermission>
      }
    >
      {customDevToolsPage}
    </Route>
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
