import { Config } from '@backstage/config';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import { PapiFeatures } from '@internal/megatron-client-common';

export interface TemplateFilters {
  // megaPapiFeatures: boolean;
  jumphost: boolean;
}

let memoizedFeatureFlags: TemplateFilters | null = null;

const getFeatureFlags = (config: Config): TemplateFilters => {
  if (memoizedFeatureFlags) {
    return memoizedFeatureFlags;
  }

  memoizedFeatureFlags = {
    // megaPapiFeatures:
    //   config.getOptionalBoolean('app.featureFlags.megaPapiFeatures') ?? false,
    jumphost: config.getOptionalBoolean('app.featureFlags.jumphost') ?? false,
  };

  return memoizedFeatureFlags;
};

const UPDATE_TEMPLATES = {
  megaPapiFeatures: Object.values(PapiFeatures).map(
    feature => `mega-${feature}-update-template`,
  ),
} as const;

/**
 * Creates a template filter function based on the provided configuration.
 *
 * This function checks the feature flags from the configuration to determine
 * whether specific templates should be included or excluded based on their
 * type and name.
 * - If the entity is not of kind 'Template', it is included by default.
 * - For PAPI features eg- workspace, subscription, spoke, namespace, domain etc templates, it checks if the feature flag for
 *   megaPapiFeatures is enabled and if the template is not an update.
 *   template.
 *
 * @param config - The configuration object containing feature flags.
 * @param hasGroupMembership - A boolean indicating whether the user has group membership.
 * @returns A function that filters templates based on the defined rules.
 */
export const createTemplateFilter = (
  config: Config,
  hasGroupMembership: boolean,
) => {
  const featureFlags = getFeatureFlags(config);

  return (entity: TemplateEntityV1beta3): boolean => {
    if (entity.kind !== 'Template') {
      return true;
    }

    const { name: templateName } = entity.metadata;
    const { type: entityType } = entity.spec;

    if (!entityType) {
      return true;
    }

    // Check for papi features related templates
    if (Object.values(PapiFeatures).includes(entityType as PapiFeatures)) {
      const isPapiFeaturesUpdate = UPDATE_TEMPLATES.megaPapiFeatures.some(
        name => templateName.includes(name),
      );
      return hasGroupMembership && !isPapiFeaturesUpdate;
    }

    // Check for jumphost-related templates
    if (entityType.includes('jumphost') && templateName.includes('jumphost')) {
      return featureFlags.jumphost;
    }

    return true;
  };
};
// Add export for testing purposes
export const resetMemoizedFlags = () => {
  memoizedFeatureFlags = null;
};
