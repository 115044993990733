import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import { useApp, useRouteRef } from '@backstage/core-plugin-api';
import {
  Content,
  ContentHeader,
  DocsIcon,
  Header,
  Page,
  SupportButton,
} from '@backstage/core-components';
import {
  EntityKindPicker,
  EntityListProvider,
  CatalogFilterLayout,
} from '@backstage/plugin-catalog-react';
import {
  ScaffolderPageContextMenu,
  TemplateGroups,
} from '@backstage/plugin-scaffolder-react/alpha';
import { TemplateGroupFilter } from '@backstage/plugin-scaffolder-react';
import {
  scaffolderTranslationRef,
  TemplateListPageProps,
} from '@backstage/plugin-scaffolder/alpha';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import {
  TranslationFunction,
  useTranslationRef,
} from '@backstage/core-plugin-api/alpha';

/*
 ** Route references for various Scaffolder plugin functionalities,
 ** including actions, task listing, editing templates, registering components,
 ** selecting templates, and viewing TechDocs.
 */
const actionsRouteRef = scaffolderPlugin.routes.actions;
const scaffolderListTaskRouteRef = scaffolderPlugin.routes.listTasks;
const editRouteRef = scaffolderPlugin.routes.edit;
const selectedTemplateRouteRef = scaffolderPlugin.routes.selectedTemplate;
const viewTechDocRouteRef = scaffolderPlugin.externalRoutes.viewTechDoc;

const createGroupsWithOther = (
  groups: TemplateGroupFilter[],
  t: TranslationFunction<typeof scaffolderTranslationRef.T>,
): TemplateGroupFilter[] => [
  ...groups,
  {
    title: t('templateListPage.templateGroups.otherTitle'),
    filter: e => ![...groups].some(({ filter }) => filter(e)),
  },
];

/**
 * @alpha
 */
export const CustomTemplateListPage = (props: TemplateListPageProps) => {
  const {
    TemplateCardComponent,
    groups: givenGroups = [],
    templateFilter,
    headerOptions,
  } = props;
  const navigate = useNavigate();
  const editorLink = useRouteRef(editRouteRef);
  const actionsLink = useRouteRef(actionsRouteRef);
  const tasksLink = useRouteRef(scaffolderListTaskRouteRef);
  const viewTechDocsLink = useRouteRef(viewTechDocRouteRef);
  const templateRoute = useRouteRef(selectedTemplateRouteRef);
  const app = useApp();
  const { t } = useTranslationRef(scaffolderTranslationRef);

  const groups = givenGroups.length
    ? createGroupsWithOther(givenGroups, t)
    : [
        {
          title: t('templateListPage.templateGroups.defaultTitle'),
          filter: () => true,
        },
      ];

  const scaffolderPageContextMenuProps = {
    onEditorClicked:
      props?.contextMenu?.editor !== false
        ? () => navigate(editorLink())
        : undefined,
    onActionsClicked:
      props?.contextMenu?.actions !== false
        ? () => navigate(actionsLink())
        : undefined,
    onTasksClicked:
      props?.contextMenu?.tasks !== false
        ? () => navigate(tasksLink())
        : undefined,
  };

  const additionalLinksForEntity = useCallback(
    (template: TemplateEntityV1beta3) => {
      const { kind, namespace, name } = parseEntityRef(
        stringifyEntityRef(template),
      );
      return template.metadata.annotations?.['backstage.io/techdocs-ref'] &&
        viewTechDocsLink
        ? [
            {
              icon: app.getSystemIcon('docs') ?? DocsIcon,
              text: t(
                'templateListPage.additionalLinksForEntity.viewTechDocsTitle',
              ),
              url: viewTechDocsLink({ kind, namespace, name }),
            },
          ]
        : [];
    },
    [app, viewTechDocsLink, t],
  );

  const onTemplateSelected = useCallback(
    (template: TemplateEntityV1beta3) => {
      const { namespace, name } = parseEntityRef(stringifyEntityRef(template));

      navigate(templateRoute({ namespace, templateName: name }));
    },
    [navigate, templateRoute],
  );

  return (
    <EntityListProvider>
      <Page themeId="home">
        <Header
          pageTitleOverride={t('templateListPage.pageTitle')}
          title={t('templateListPage.title')}
          subtitle={t('templateListPage.subtitle')}
          {...headerOptions}
        >
          <ScaffolderPageContextMenu {...scaffolderPageContextMenuProps} />
        </Header>
        <Content>
          <ContentHeader>
            <SupportButton>
              {t('templateListPage.contentHeader.supportButtonTitle')}
            </SupportButton>
          </ContentHeader>

          <CatalogFilterLayout>
            <CatalogFilterLayout.Content>
              <TemplateGroups
                groups={groups}
                templateFilter={templateFilter}
                TemplateCardComponent={TemplateCardComponent}
                onTemplateSelected={onTemplateSelected}
                additionalLinksForEntity={additionalLinksForEntity}
              />
            </CatalogFilterLayout.Content>
            <EntityKindPicker initialFilter="template" hidden />
          </CatalogFilterLayout>
        </Content>
      </Page>
    </EntityListProvider>
  );
};
