import React, { useEffect, useState, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { debounce } from '@mui/material/utils';

export interface AzureUser {
  displayName?: string;
  givenName?: string;
  id?: string;
  jobTitle?: string;
  mail: string;
  mobilePhone?: number;
  officeLocation?: string;
  preferredLanguage?: string;
  surname?: string;
  userPrincipalName?: string;
}

export interface FetchAzureUserAutoCompleteComponentProps {
  handleSelect?: (selectedUser: AzureUser) => void;
  width?: string;
  defaultValue?: AzureUser;
  label?: string;
}

export const FetchAzureUserAutoCompleteComponent = (
  props: FetchAzureUserAutoCompleteComponentProps,
) => {
  const { handleSelect, width, defaultValue, label } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly AzureUser[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const microsoftAuthApi = useApi(microsoftAuthApiRef);
  const loading = open && options.length === 0;
  const defaultLabel = 'Type Email of User';

  const fetchUsers = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (results?: readonly AzureUser[]) => void,
        ) => {
          try {
            const accessToken =
              await microsoftAuthApi.getAccessToken('User.ReadBasic.All');
            // Fetching users from microsoft graph API
            const userListOption = await fetch(
              `https://graph.microsoft.com/v1.0/users?$filter=startswith(mail,'${request.input}')`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`,
                },
              },
            )
              .then(resp => resp.json())
              .then(data => (data && data.value ? data.value : []));
            callback(userListOption);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
          }
        },
        1000,
      ),
    [microsoftAuthApi],
  );

  useEffect(() => {
    let active = true;
    if (searchQuery.length < 3 || !open) {
      setOptions([]);
      return undefined;
    }
    fetchUsers({ input: searchQuery }, (results?: readonly AzureUser[]) => {
      if (active) {
        let newOptions: readonly AzureUser[] = [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [fetchUsers, searchQuery, open]);

  const handleOptionSelect = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: AzureUser | null,
  ): void => {
    event.stopPropagation();
    if (newValue && handleSelect) {
      handleSelect(newValue);
    }
  };

  const optionsWithDefaultValue = [defaultValue ?? { mail: '' }, ...options];

  return (
    <Autocomplete
      id="fetch-azure-users"
      sx={{ width: width ? width : 600 }}
      ListboxProps={{
        sx: {
          cursor: 'pointer', // Change the cursor to a pointer hand icon
        },
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={option => option.mail}
      options={options}
      noOptionsText="No User Found.."
      loading={loading}
      isOptionEqualToValue={(option, value) => option.mail === value.mail}
      onChange={handleOptionSelect}
      defaultValue={optionsWithDefaultValue[0]}
      renderInput={params => (
        <TextField
          {...params}
          label={label ?? defaultLabel}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
