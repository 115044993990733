import { createApiRef } from '@backstage/core-plugin-api';
import { BaseMegatronApiClient } from './baseApi';
import {
  HttpMethod,
  PapiEndpoints,
  Subscription,
  Workspace,
} from '@internal/megatron-client-common';

export interface MegatronApi {
  getAllWorkspaces: () => Promise<Array<Workspace>>;
  getWorkspace: (workSpaceId: string) => Promise<Workspace>;
  getAllSubscriptions: () => Promise<Array<Subscription>>;
  getSubscription: (subscriptionId: string) => Promise<Subscription>;
  deleteWorkspace: (workSpaceId: string) => Promise<void>; // Updated to return a Promise
  deleteSubscription: (subsId: string) => Promise<void>;
  deleteSpoke: (spokeId: string) => Promise<void>;
}

export const megatronApiRef = createApiRef<MegatronApi>({
  id: 'plugin.megatron.service',
});

export class MegatronApiClient
  extends BaseMegatronApiClient
  implements MegatronApi
{
  async getAllWorkspaces(): Promise<Array<Workspace>> {
    const response = await this.fetchWithAuth(PapiEndpoints.WORKSPACE, {
      method: HttpMethod.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  }

  async getWorkspace(workspaceId: string): Promise<Workspace> {
    const response = await this.fetchWithAuth(
      `${PapiEndpoints.WORKSPACE}/${workspaceId}`,
      {
        method: HttpMethod.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.json();
  }

  async getAllSubscriptions(): Promise<Array<Subscription>> {
    const response = await this.fetchWithAuth(PapiEndpoints.SUBSCRIPTION, {
      method: HttpMethod.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  }

  async getSubscription(subscriptionId: string): Promise<Subscription> {
    const response = await this.fetchWithAuth(
      `${PapiEndpoints.SUBSCRIPTION}/${subscriptionId}`,
      {
        method: HttpMethod.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.json();
  }

  async deleteWorkspace(workSpaceID: string): Promise<void> {
    // Implementing deleteWorkspace
    await this.fetchWithAuth(`${PapiEndpoints.WORKSPACE}/${workSpaceID}`, {
      method: HttpMethod.DELETE,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async deleteSubscription(subsId: string): Promise<void> {
    // Implementing deleteSubscription
    await this.fetchWithAuth(`${PapiEndpoints.SUBSCRIPTION}/${subsId}`, {
      method: HttpMethod.DELETE,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async deleteSpoke(spokeId: string): Promise<void> {
    // Implementing deleteSpoke
    await this.fetchWithAuth(`${PapiEndpoints.SPOKE}/${spokeId}`, {
      method: HttpMethod.DELETE,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
