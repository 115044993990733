import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import {
  FetchAzureUserAutoCompleteComponent,
  AzureUser,
} from 'mega-components'; // Adjust the import path as necessary

// Custom field extension component
export const AzureUserPicker = ({
  onChange,
  formData,
  title,
}: FieldExtensionComponentProps<any>) => {
  const label = title ? `Type Email of ${title}` : undefined;
  const handleSelect = (selectedUser: AzureUser) => {
    onChange(selectedUser.mail); // Set the email of the selected user as the field value
  };
  return (
    <FetchAzureUserAutoCompleteComponent
      handleSelect={handleSelect}
      width="100" // Adjust width as needed
      defaultValue={{
        mail: formData ?? '',
      }}
      label={label}
    />
  );
};
