import {
  useTemplateSecrets,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import React, { useEffect, useState } from 'react';
import {
  useApi,
  microsoftAuthApiRef,
  configApiRef,
} from '@backstage/core-plugin-api';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ErrorIcon from '@mui/icons-material/Error';

export const AuthTokenPicker = ({
  onChange,
}: FieldExtensionComponentProps<any>) => {
  // Access the setSecrets method from the useTemplateSecrets hook.
  const { setSecrets } = useTemplateSecrets();

  // Get the Microsoft authentication API reference from the core API.
  const microsoftAuthApi = useApi(microsoftAuthApiRef);

  // Get the configuration API reference to access application configuration settings.
  const config = useApi(configApiRef);

  // State to manage the loading status (initially set to false)
  const [loading, setLoading] = useState<boolean>(false);

  // State to manage any potential error messages (initially set to null, indicating no error)
  const [error, setError] = useState<string | null>(null);

  /**
   * useEffect hook to fetch the token when the component mounts.
   * Fetch the access token from Microsoft API and save it in template secrets.
   */
  useEffect(() => {
    // Function to fetch token and scope
    const fetchToken = async () => {
      try {
        setLoading(true);
        // Retrieve the scope for accessing the Microsoft API from the configuration.
        const scope = config.getString('megatron.papi.scope');

        // Fetch the token using Microsoft Auth API with the retrieved scope
        const accessToken = await microsoftAuthApi.getAccessToken(scope);

        // Store the token in secrets
        setSecrets({ AUTH_TOKEN: accessToken });

        // Trigger the onChange callback
        onChange(accessToken);
      } catch (err: any) {
        setError(
          `Failed to fetch access token from Microsoft API: ${
            err instanceof Error ? err.message : String(err)
          }`,
        );
      } finally {
        setLoading(false); // Stop loader when done
      }
    };

    // Call the token fetcher when the component mounts
    fetchToken();
  }, [microsoftAuthApi, config, setSecrets, onChange]);

  // If there's an error, display an error message with an error icon
  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 1.5,
          padding: 1.5,
        }}
      >
        <ErrorIcon color="error" />
        <Typography variant="body1" sx={{ fontWeight: 500 }} color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return loading ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start', // Align content to the left
        gap: 1.5, // Reduce spacing between loader and text
        padding: 1.5,
      }}
      aria-live="polite"
    >
      <CircularProgress
        color="primary"
        aria-label="Fetching access token"
        title="Fetching access token"
        sx={{
          animationDuration: '550ms',
          width: '24px !important',
          height: '24px !important',
        }}
      />
      <Typography variant="body2" sx={{ fontWeight: 500 }} color="primary">
        Retrieving user token. Please wait...
      </Typography>
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 1,
        padding: 1.5,
      }}
    >
      <CheckBoxIcon color="success" />
      <Typography variant="body1" sx={{ fontWeight: 600 }} color="primary">
        User token retrieved successfully
      </Typography>
    </Box>
  );
};
